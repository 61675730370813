<template>
  <form @keydown.enter="changePassword()">
    <h1>{{ $t("Zmeniť heslo") }}</h1>

    <UserPasswordChange :user="user" showRepeat="true" />

    <b-button
      variant="primary"
      @click.prevent="changePassword()"
      :disabled="$toast.isLoading()"
      >{{ $t("Uložiť nové heslo") }}</b-button
    >
  </form>
</template>

<script>
import * as Sentry from "@sentry/vue";
export default {
  components: {
    UserPasswordChange: () => import("/components/UserPasswordChange"),
  },

  data() {
    return {
      user: {
        password: "",
        password_confirmation: "",
      },
    };
  },

  methods: {
    async changePassword() {
      this.$toast.clear_loading();

      if (!this.user.password.length) {
        this.$toast.error(this.$t("Heslo nie je vyplnené"));
        return;
      }

      if (this.user.password !== this.user.password_confirmation) {
        this.$toast.error(this.$t("The password confirmation does not match."));
        return;
      }

      try {
        await this.$store.dispatch("auth/updateUser", this.user);
        this.$toast.success(this.$t("Vaše heslo bolo úspešne zmenené"));
        this.user.password = "";
        this.user.password_confirmation = "";
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
  },
};
</script>
